.modal-overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
.modal-title{
    margin-left: 1.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: var(--color-bg);
    padding: 20px;
    border-radius: 2rem;
    width: 40%;
}

.modal-close__button{
    width: 30px;
    font-size: 20px;
    color: #c0c5cb;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
    float: right;
    cursor: pointer;
}

.modal-close__button:hover{
  color: var(--color-primary);
  transition: var(--transition);
}

.modal-image{
    width: 50px;
    height: 50px;
}

.modal-image img{
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.modal-button__container{
  width: 100%;
  margin-bottom: 10px;

}
.modal-description{
  text-align: center;
  color: var(--color-light);
  font-size: "0.5rem"
}

.modal-tools__container{
  padding: "1.2rem";
  margin: 0 auto;
  width: 70%;
}

.modal-tool__item{
  display: flex;
  gap: 0.6rem;
}

.modal__tools-icon{
  margin-top: 0.1rem;
  color: var(--color-primary);
}

.modal-tools{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 1.2rem;
  margin-bottom: 30px;
}

.modal-tools__header{
  margin: 1.2rem;
  text-align: center;
}


.modal-content p{
  font-size: 0.9rem;
}



@media  screen and (max-width: 1300px) {

  .modal-content{
    width: 65%;
  }

  .modal-tools__container{
    width: 80%;
    margin: 0 auto;
  }
 
}


@media  screen and (max-width: 1025px) {
  .modal-tools{
    grid-template-columns: 1fr 1fr 1fr ;
  }

}

/* Media Queries (phones) */

@media  screen and (max-width: 600px) {

  .modal-tools{
    width: 100%;
    grid-template-columns: 1fr 1fr ;
  }
  .modal-content{
    width: 95%;
  }

  .modal-tools__container{
    width: 85%;
  }

}