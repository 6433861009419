/* The animation code */
@keyframes colorChange {
    0% {
      color: #000;
    }
    100% {
      color: #ecc92d;
    }
  }

.portfolio__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item-image img {
    width: 100%; /* Ensures the image takes the full width of its container */
    height: 200px; /* Set a fixed height */
    object-fit: cover; /* Ensures the image scales to fit the width and height without distortion */
    border-radius: 8px; /* Optional: Adds a border radius for a rounded edge effect */
  }
  

.portfolio__item{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: all .2s ease-in-out;
}

.portfolio__item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
    transform: scale(1.08);
}

.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item-name:hover{
     animation: colorChange 0.5s ease-in;
     color: #ecc92d
}

.portfolio__item h3{
    cursor: pointer;
    transition: all .2s ease-in-out;
    height: 2rem;
    margin: 1.2rem 0 2rem;
    transition: var(--transition);
    box-sizing: border-box;

}

.portfolio__item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}


 /* Media Queries (Tablets ) */

 @media  screen and (max-width: 1024px) {

    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

}
 /* Media Queries (phones) */

 @media  screen and (max-width: 600px) {
    .portfolio__container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
