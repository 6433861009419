
/* ========================= CUBE STYLE ========================= */

.stage-cube-cont {
    padding-top: 5%;
    padding-bottom: 35%;
    position: relative;
  }
  
  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);
  
    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px lightyellow;
    }
  
    .face1 {
      transform: translateZ(100px);
      color: #dd0031;
    }
    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #f06529;
    }
    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #28a4d9;
    }
    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #5ed4f4;
    }
    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #efd81d;
    }
    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #ec4d28;
    }
  }
  
  @keyframes spincube {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg);
    }
    33% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }
  }


.about__container{
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 12%;
}

.about__me{
    width: 100%;
    aspect-ratio: 1/1;
    display: grid;
    place-items: center;
}

#about a{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
}

.about__cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card{
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.about__card:hover{
    background: transparent;
    border-color: var(__color-primary-variant);
    cursor: default;
}

.about__icon{
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5{
    font-size: 0.95rem;
}

.about__card small{
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p{
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

/* Media Queries (Tablets ) */

@media  screen and (max-width: 1024px) {

    .about__container{
        grid-template-columns: 1fr;
        gap: 0;
    }


    .about__content p{
        margin: 1rem 0 1.5rem;
        text-align: left;
        padding: 1rem;
    }

    .about__content a{
        margin-left: 1rem;
    }

    .stage-cube-cont {
      margin-bottom: 10%;
  }

}
 /* Media Queries (phones) */

 @media  screen and (max-width: 600px) {
    .about__me{
        width: 100%;
        margin-right:1.2rem ;
        overflow: hidden;
    }

    .about__cards{
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__content{
        text-align: center;
    }

    .about__content p{
        margin: 1.3rem 0;
    }

    .stage-cube-cont {
      margin-bottom: 52%;
  }

 }